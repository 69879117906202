@use "@angular/material" as mat;
@import "@angular/material/theming";

@include mat.core();

@import "ag-grid-community/dist/styles/ag-grid.scss";
//@import "ag-grid-community/dist/styles/ag-theme-material/sass/ag-theme-material-mixin.scss";
@import "ag-grid-community/dist/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin.scss";

@import "app-theme";

@include mat.all-component-themes($xo-app-theme);

html,
body {
  height: 100%;
  padding: 0;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.small-table {
  overflow-x: auto !important;
}

.full-width-table {
  width: 100%;
}

.fill-remaining-space {
  flex: 1 1 auto;
}

.container-with-centered-content {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: calc(100% - 94px);
  width: calc(100% - 20px);
  padding: 10px;
}

.container-with-top-centered-content {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  height: calc(100% - 94px);
  width: calc(100% - 20px);
  padding: 10px;
}

.text-shadow-1x {
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.5);
}

.text-shadow-2x {
  text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.5);
}

$primary: map-get($xo-app-theme, primary);
$accent: map-get($xo-app-theme, accent);
$warn: map-get($xo-app-theme, warn);
$success: map-get($xo-app-theme, success);
$bg: map-get($xo-app-theme, background);

.error-notification-overlay {
  color: mat.get-contrast-color-from-palette($warn, default);
  background-color: mat.get-color-from-palette($warn);
}

.information-notification-overlay {
  color: mat.get-contrast-color-from-palette($primary, default);
  background-color: mat.get-color-from-palette($primary);
}

.success-notification-overlay {
  color: mat.get-contrast-color-from-palette($success, default);
  background-color: mat.get-color-from-palette($success);
}

.warning-notification-overlay {
  color: mat.get-contrast-color-from-palette($accent, default);
  background-color: mat.get-color-from-palette($accent);
}

button.mat-success,
input.mat-success,
textarea.mat-success,
.mat-success {
  color: mat.get-contrast-color-from-palette($success, default);
  background-color: mat.get-color-from-palette($success);
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.pad-5 {
  padding: 5px;
}

.pad-10 {
  padding: 10px;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: mat.get-color-from-palette($primary, default)
    rgba(0, 0, 0, 0.5);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.5);
}

*::-webkit-scrollbar-thumb {
  background-color: mat.get-color-from-palette($primary);
  border: 2px solid mat.get-color-from-palette($primary, darker);
}

.color-test {
  color: white;
  background-color: mat.get-color-from-palette($bg, background, 0.5);
}

.ag-theme-alpine {
  @include ag-theme-alpine(
    (
      odd-row-background-color: #ddd,
    )
  );
}

//.ag-theme-material {
//  @include ag-theme-material((
//
//    header-background-color: mat.get-color-from-palette($primary, default),
//    background-color: #555,
//    odd-row-background-color: #444,
//    material-primary-color: mat.get-color-from-palette($primary, default),
//    material-accent-color: mat.get-color-from-palette($accent, default),
//  ));
//}
